import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const French = () => (
  <Layout>
    <SEO title="Français" />
    <div class="banner">
      <div class="container">
        <div class="colx1">
          <div class="copy">
            <h4 class="center">
              Cravv<sup>®</sup> est un produit de santé naturel pour le sevrage tabagique. Il est plus efficace que le traitement de substitution à la nicotine pour la cessation tabagique et coûte moins cher que de nombreux produits pour arrêter de fumer traditionnels. Chaque bouteille contient le traitement complet de 25 jours pour vous aider à cesser de fumer!
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="colx1 center">
          <h6>
            Chaque capsule orale contient 1,5 mg de cytisine.

            Vous devrez réduire le nombre de cigarettes que vous fumez progressivement au cours des premiers jours de traitement et cesser de fumer au plus tard au cinquième jour de traitement.

            

            La posologie est de 1 capsule 6 fois par jour toutes les 2 heures avec une réduction correspondante du nombre de cigarettes fumées pendant les 3 premiers jours.

            

            

            

            Vous devriez arrêter de fumer le cinquième jour après le début du traitement.

            

            
          </h6>
        <h6>
            • 4ème au 12ème jour: 1 capsule toutes les 2,5 heures (5 capsules par jour)
            <br/>
            • 13ème au 16ème jour: 1 capsule toutes les 3 heures (4 capsules par jour)
            <br/>
            • 17ème au 20ème jour: 1 capsule toutes les 5 heures (3 capsules par jour)
            <br/>
            • 21ème au 25ème jour: 1 à 2 capsules par jour
        </h6>
        <h6>
        Les effets secondaires les plus couramment observés lors des essais cliniques ont été les nausées, les maux d’estomac et les troubles du sommeil.
        </h6>
        </div>
      </div>
    </div>
  </Layout>
)

export default French
